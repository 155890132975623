import { Accordion } from './';

class MenuAccordion {
  constructor(el) {
    this.dom = {el: el, acd: [], container: null};
    const menu = el.querySelectorAll('[data-js="menu-accordion"]');  
    [...menu].forEach((el, index)=>{
      this.dom.acd.push({
        trigger: el.querySelector('[data-js="acd-trigger"]'),
        container: el.querySelector('[data-js="acd-container"]'),
        class: new Accordion(el, true),
        index: index,
      });
    });
    this.init();
  }

  init() {
    [...this.dom.acd].forEach(el=>{
      el.trigger.addEventListener('click', (e)=>{
        this.check(el, e);
      });
    });
  }

  check(target, e) {
    [...this.dom.acd].forEach(el=>{
      if (el.class.isOpen && el.index !== target.index) {
        el.class.toggle();
      }
    });

    target.class.toggle(e);
  }
}

export default MenuAccordion