import { Splide } from '@splidejs/splide';
import '@splidejs/splide/css/core';

class OtherInterviewSlides {
  constructor(el) {
    this.dom = {el: el};
    this.dom.slider = this.dom.el.querySelector('.splide');

    this.direction = this.dom.el.dataset.dir;
    // this.len = this.dom.slider.querySelectorAll('.splide__slide').length;

    this.slider = null;
    this.option = {
      autoWidth: true,
      pagination: false,
      arrows: false,
      pauseOnHover: false,
      focus: 'center',
      type: 'loop',
      gap: `min(${48 / 16}rem, ${48 / 375 * 100}vw)`,
      padding: `min(${30 / 16}rem, ${30 / 375 * 100}vw)`,
      // rewind: true,
      // loop: true,
      // // perPage: 1,
      // // autoWidth: true,
      speed: 1200,
      // drag: false,
      // arrowPath: false,
      // autoplay: true,
      // pauseOnHover: false,
      // interval: 4000,
      // lazyLoad: 'nearby',
      // drag: false,
      // items:PaginationItem[]
      mediaQuery: 'min',
      breakpoints: {
        768: {
          // type: 'loop',
          gap: `min(${72 / 16}rem, ${72 / 1440 * 100}vw)`,
          padding: `min(${97.5 / 16}rem, ${97.5 / 375 * 100}vw)`,
          // focus: 'center',
        }
      }
    };

    this.init();
  }

  init() {
    this.slider = new Splide(this.dom.slider, this.option);
    this.slider.mount();
  }
}

export default OtherInterviewSlides