import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// recruit
// import {
//   AboutScrollTrigger,
// } from './'

class CircleScrollTrigger {
  constructor(el) {
    this.dom = {el: el};
    // this.dom.circleTrigger = this.dom.el;
    this.dom.circleTrigger = this.dom.el.querySelector('[data-js="circle-trigger"]');
    this.dom.circleTarget = this.dom.el.querySelector('[data-js="circle-target"]');
    // this.dom.aboutSpacer = this.dom.el.querySelector('[data-js="about-spacer"]');
    // this.dom.aboutTrigger = this.dom.el.querySelector('[data-js="about-trigger"]');

    gsap.registerPlugin(ScrollTrigger);

    this.init();
  }

  init() {
    gsap.set(this.dom.circleTarget, {
      scale: .2
    });
    
    gsap.to(this.dom.circleTarget, {
      scale: 1,
      scrollTrigger: {
        trigger: this.dom.circleTrigger,
        start: 'top 100%',
        end: 'top 20%',
        scrub: true,
      }
    });

    // gsap.set(this.dom.aboutSpacer, {
    //   height: '100vh'
    // });

    // gsap.to(this.dom.aboutSpacer, {
    //   height: 0,
    //   scrollTrigger: {
    //     trigger: this.dom.el,
    //     start: 'top 50%',
    //     end: 'top 20%',
    //     scrub: true,
    //   },
    //   onComplete: () => {
    //     console.log('finished')
    //     // const domAboutScrollTrigger = document.querySelector('[data-js="about-trigger"]');
    //     if (this.dom.aboutTrigger) new AboutScrollTrigger(this.dom.aboutTrigger);
      
    //   }
    // });

    // ScrollTrigger.create({
    //   trigger: this.dom.circle,
    //   id: 'stc',
    //   start: 'top 80%',
    //   end: 'bottom -1000%',
    //   toggleClass: {
    //     targets: this.dom.target,
    //     className: 'is-hidden'
    //   },
    //   once: false,
    //   markers: false,
    // });
  }
}

export default CircleScrollTrigger