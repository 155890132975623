import { CFG } from '../config';
import { isIE } from '../utility';

class HamburgerPartTIme {
  constructor(elms) {
    this.dom = {elms: elms};
    this.dom.trigger = this.dom.elms;
    this.dom.menu = document.querySelector('#js-menu');
    this.dom.closer = document.querySelectorAll('[data-js="closer"]');
    this.dom.body = document.querySelector('body');
    this.dom.header = document.querySelector('.st-header');

    this.isOpen = false;

    this.toggleMenu = this.toggleMenu.bind(this);
    this.scroll = this.scroll.bind(this);

    this.mql = window.matchMedia('screen and (min-width: 768px)');
    this.checkBreakPoint = this.checkBreakPoint.bind(this);

    this.hash = null;

    this.init();
  }

  init() {
    [...this.dom.trigger].forEach(el=>{
      el.addEventListener('click', e => {
        this.toggleMenu();
      });
    });
    [...this.dom.closer].forEach(el=>{
      el.addEventListener('click', e => {
        this.toggleMenu();
      });
    })

    // ページ内リンク
    const domAnchorScroll = [...document.querySelectorAll('a[href^="#"]:is(.is-noscroll)')];
    if ( domAnchorScroll ) {
      domAnchorScroll.forEach(el => {
        el.addEventListener('click', this.scroll);
      });
    }
  
    // ブレイクポイントの瞬間に発火
    this.mql.addListener(this.checkBreakPoint);
    // 初回チェック
    this.checkBreakPoint(this.mql);
  }

  toggleMenu() {
    if (this.isOpen) {
      this.dom.trigger[0].setAttribute('aria-expanded', false);
      this.dom.menu.setAttribute('aria-hidden', true);
      this.scrollEnable();
    } else {
      this.dom.trigger[0].setAttribute('aria-expanded', true);
      this.dom.menu.setAttribute('aria-hidden', false);
      this.scrollDisable();
    }

    if ( document.body.dataset.isMenu === 'true' ) {
      document.body.removeAttribute('data-is-menu');
    } else {
      document.body.dataset.isMenu = 'true';
    }

    this.isOpen = !this.isOpen;
  }

	scrollDisable() {
    document.body.dataset.isScrolldisable = 'true';
	}

	scrollEnable() {
    const _this = this;
    document.body.removeAttribute('data-is-scrolldisable');
	}

  checkBreakPoint(_mql) {
		if (!_mql.matches) {
      // console.log('sp');
      return;
    } else {
      // console.log('pc');
      if (this.isOpen) {
        this.dom.trigger[0].setAttribute('aria-expanded', false);
        this.dom.menu.setAttribute('aria-hidden', true);
        this.scrollEnable();

        this.isOpen = !this.isOpen;
        if( document.body.dataset.isMenu === 'true' ) {
          document.body.removeAttribute('data-is-menu');
        } else {
          document.body.dataset.isMenu = 'true';
        }
      }
      return;
		}
	}

  scroll(e) {
    e.preventDefault();

    this.toggleMenu();

    const href = e.currentTarget.getAttribute('href');
    this.hash = href.match(/\#.*$/)[0];

    const target = document.querySelector(this.hash);

    if (/pagetop/.test(this.hash)) {
      target.scrollIntoView({behavior:'smooth'});
    } else {
      // let hh = this.dom.header.clientHeight + 32;
      const rect = target.getBoundingClientRect().top;
      // 上に戻るときはナビが表示されるので、余白を作る
      let hh = (rect >= 0) ? 0 : 157;
      const pyo = window.pageYOffset;
      const top = rect + pyo - hh;
      // const top = rect + pyo;

      if (top == 0) {
        target.scrollIntoView({behavior:'smooth'});
      } else {
        window.scroll({top: top, behavior: 'smooth'});
      }
    }
  }
}

export default HamburgerPartTIme