import Swiper from 'swiper';
// import { Navigation } from 'swiper/modules';
// import 'swiper/css';
import 'swiper/css/swiper.css';
// Swiper.use([Navigation]);

class PeopleSlides {
  constructor(el) {
    this.dom = {el: el};
    this.dom.slider = this.dom.el.querySelector('.swiper-container');
    this.dom.prev = this.dom.el.querySelector('.swiper-button--prev');
    this.dom.next = this.dom.el.querySelector('.swiper-button--next');

    // this.direction = this.dom.el.dataset.dir;
    // this.len = this.dom.slider.querySelectorAll('.splide__slide').length;

    this.slider = null;
    this.option = {
      // modules: [Navigation],
      speed: 1000,
      slidesPerView: 'auto',
      // freeMode: true,
      // freeMode: {
      //   enabled: true,
      //   momentumRatio: 0.3,
      //   momentumVelocityRatio: 0.35,
      // },
      updateOnWindowResize: false,
      loop: true,
      // centeredSlides: true,
      // rewind: true,
      // spaceBetween: `min(${20 / 16}rem, ${20 / 375 * 100}vw)`,
      spaceBetween: 20,
      breakpoints: {
				768: {
          spaceBetween: 48,
				}
			},
      navigation: {
        nextEl: this.dom.next,
        prevEl: this.dom.prev,
      },
      // pagination: {
			// 	el: '.top_interview_bar_sec ._bar',
			// 	type: "progressbar",
			// },
      // on: {
      //   slidePrevTransitionStart: () => {
      //     const prev = this.dom.el.querySelector('.swiper-slide-prev');
      //     console.log(prev)
      //     prev.classList('swiper-slide-active');
      //     console.log('slidePrevTransitionStart');
      //   },
      //   slideNextTransitionStart: () => {
      //     const prev = this.dom.el.querySelector('.swiper-slide-prev');
      //     prev.classList('swiper-slide-active');
      //     console.log('slideNextTransitionStart');
      //   },
      // },
    }

    this.init();
  }

  init() {
    const _this = this;
    setTimeout(()=>{
      this.slider = new Swiper(this.dom.slider, this.option);
      // this.slider.slideToLoop(0, 0);
    }, 300);

    // this.slider.on('slideChange', function() {
    //   console.log('slideChange')
    //   _this.slider.update();
    // });
  }
}

export default PeopleSlides