class ScenePopup {
  constructor(elms) {
    this.dom = {elms: elms};

    this.toggle = this.toggle.bind(this);
    this.init();
  }

  init() {
    [...this.dom.elms].forEach(el=>{
      el.addEventListener('click', this.toggle);
    });
  }

  toggle(e) {
    const target = e.currentTarget;
    if (target.dataset.is !== 'close') {
      target.dataset.is = 'close';
    } else {
      target.dataset.is = 'open';
    }
  }
}
export default ScenePopup