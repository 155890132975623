export default class {
  constructor(el) {
    this.dom = {el: el};
    this.dom.body = document.querySelector('body');
    this.dom.entry = document.querySelector('#entry');
    this.dom.entryButton = document.querySelector('[data-button="entry"]');

    this.dom.scroller = this.dom.el;

    this.scrollJudge = 0;
    this.scrollJudgeLower = 0;
    this.posPrevious = 0;
    this.hh = (this.dom.scroller) ? this.dom.scroller.clientHeight : 0;

    this.setPosition = 0;
    this.scrollAdj = 0;

    this.entryPosition = 0;

    // this.dom.body.dataset.scrollDirection = 'down';
    // this.flagUp = false;
    this.flagUp = (this.dom.body.dataset.scrollDirection === 'false') ? false : true;

    this.mql = window.matchMedia('screen and (min-width: 768px)');
    this.checkBreakPoint = this.checkBreakPoint.bind(this);

    this.update = this.update.bind(this);
    this.resize = this.resize.bind(this);
    this.scroll = this.scroll.bind(this);

    this.init();
  }

  init() {
    this.setVariable();
    this.update();
    window.addEventListener('resize', this.resize);
    window.addEventListener('scroll', this.scroll);

    // ブレイクポイントの瞬間に発火
    this.mql.addListener(this.checkBreakPoint);
    // 初回チェック
    this.checkBreakPoint(this.mql);
  }

  update() {
    // let pyo = window.pageYOffset + this.hh - this.dom.scroller.clientHeight;
    let pyo = window.pageYOffset;
    pyo = pyo < 0 ? 0 : pyo;
    // console.log({pyo})

    // this.flagUp = (this.dom.body.dataset.scrollAction !== 'true') ? false : true;

    if (pyo > this.scrollJudge) {
      document.body.dataset.scrollAction = 'true';
    } else {
      document.body.removeAttribute('data-scroll-action');
    }

    if (pyo > this.scrollJudgeLower) {
      document.body.dataset.scrollActionLower = 'true';
    } else {
      document.body.removeAttribute('data-scroll-action-lower');
    }

    if (pyo > this.menuJudge) {
      document.body.dataset.menu = 'true';
    } else {
      document.body.removeAttribute('data-menu');
    }

    if (pyo > this.entryPosition) {
      this.dom.entryButton.dataset.display = 'hidden';
    } else {
      this.dom.entryButton.removeAttribute('data-display');
    }

    requestAnimationFrame(this.update);
  }

  scroll() {
    if (this.setPosition < document.documentElement.scrollTop) {
      this.dom.body.dataset.scrollDirection = 'down';
    } else {
      this.dom.body.dataset.scrollDirection = 'up';
    }
    this.setPosition = document.documentElement.scrollTop;
  }

  // スクロールの基準となる位置を設定
  setVariable() {
    // ヘッダが隠れたと判断する位置
    this.hh = this.dom.scroller.clientHeight;
    this.scrollJudge = window.innerHeight;
    this.scrollJudgeLower = window.innerHeight * .5;
    // this.scrollJudge = 100;

    let entryRect = this.dom.entry.getBoundingClientRect();
    this.entryPosition = entryRect.top + window.scrollY - window.innerHeight;
    // this.entryPosition = entryRect.top;
    // console.log('this.entryPosition', this.entryPosition)
  }

  resize() {
    this.setVariable();
  }

  checkBreakPoint(_mql) {
		if (!_mql.matches) {
      // console.log('sp');
      this.menuJudge = window.innerHeight;
      return;
    } else {
      // console.log('pc');
      this.menuJudge = window.innerHeight * .5;
      return;
		}
	}
}
