import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

class AboutScrollTrigger {
  constructor(el) {
    gsap.registerPlugin(ScrollTrigger);

    this.dom = {el: el};
    this.dom.trigger = this.dom.el;
    this.dom.container = this.dom.el.querySelector('[data-js="about-container"]');
    this.dom.slides = this.dom.el.querySelectorAll('[data-js="about-slide"]');

    this.slideLength = this.dom.slides.length;
    this.slideW = {
      sp: 315 + 30,
      pc: 792 + 96
    }
    this.baseW = {
      sp: 375,
      pc: 936
    }
    this.slideWidth = 0;
    this.moveX = 0;
    this.end = 0;

    this.mql = window.matchMedia('screen and (min-width: 768px)');    
    this.checkBreakPoint = this.checkBreakPoint.bind(this);

    this.init();
  }

  init() {
    const _this = this;

    // ブレイクポイントの瞬間に発火
    this.mql.addListener(this.checkBreakPoint);
    // 初回チェック
    this.checkBreakPoint(this.mql);

    
    gsap.to(this.dom.container, {
      xPercent: this.moveX,
      ease: 'none',
      scrollTrigger: {
        trigger: this.dom.trigger,
        start: 'top 0%',
        end: this.end,
        pin: true,
        scrub: 1,
        // onToggle: (self) => console.log('toggled, isActive:', self.isActive),
        onUpdate: (self) => {
          // console.log(
          //   'progress:', self.progress.toFixed(2),
          //   'direction:', self.direction,
          //   'velocity', self.getVelocity()
          // );
          // console.log({self})
          if (self.progress.toFixed(2) < 0.1 && self.direction === -1) {
            this.dom.trigger.classList.remove('is-start');
          } else {
            this.dom.trigger.classList.add('is-start');
          }
        },
      },
      // onStart: () => {
      //   this.dom.trigger.classList.add('is-start');
      // },
      // onReverseComplete: () => {
      //   this.dom.trigger.classList.remove('is-start');
      // },
    });
  }

  set() {
    gsap.set(this.dom.container, {
      width: `${this.slideWidth}%`
    });
    // gsap.set(this.dom.slides, {
    //   width: 100 / this.slideLength + '%'
    // });
  }

  checkBreakPoint(_mql) {
		if (!_mql.matches) {
      // console.log('sp');
      const fullLength = this.slideLength * this.slideW.sp + 30;
      // スクロールする要素の全長
      this.slideWidth = fullLength / (this.baseW.sp) * 100;
      // スクロールに連動してスライドさせる%
      this.moveX = -((fullLength - this.baseW.sp) / fullLength) * 100;
      this.end = '+=750';
      this.set();
      
      return;
    } else {
      // console.log('pc');
      const fullLength = this.slideLength * this.slideW.pc;
      // スクロールする要素の全長
      this.slideWidth = fullLength / (this.baseW.pc) * 100;
      // スクロールに連動してスライドさせる%
      // this.moveX = -(((fullLength + 432) - this.baseW.pc) / fullLength) * 100;
      this.moveX = -((fullLength - this.baseW.pc) / fullLength) * 100;
      this.end = '+=1000';
      this.set();
      
      return;
		}
	}
}

export default AboutScrollTrigger