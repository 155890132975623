import { Splide } from '@splidejs/splide';
import '@splidejs/splide/css/core';

class TopSlides {
  constructor(el) {
    this.dom = {el: el};
    this.dom.slider = this.dom.el.querySelector('.splide');

    this.slider = null;
    this.option = {
      autoplay: true,
      type: 'fade',
      rewind: true,
      pauseOnHover: false,
      pauseOnFocus: false,
      interval: 5000,
      speed: 2000,
      arrows: false,
      pagination: false,
      mediaQuery: 'min',
      breakpoints: {
        768: {
          destroy: true,
        }
      }
    };

    this.init();
  }

  init() {
    this.slider = new Splide(this.dom.slider, this.option);
    this.slider.mount();
  }
}

export default TopSlides