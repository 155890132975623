import smoothscroll from 'smoothscroll-polyfill';
import { CFG } from './config';
import {
  Hamburger,
  HamburgerPartTIme,
  Accordion,
  MenuAccordion,
  AnchorScroll,
  AutoMovingSlides,
  RelatedSlides,
  ScrollManager,
  LoopTextAnimation,
} from './_main';

// top
import {
  TopSlides,
} from './_top'

// recruit
import {
  VisualSlides,
  CircleScrollTrigger,
  AboutScrollTrigger,
  PeopleSlides,
} from './_recruit'

// strength
import {
  ScenePopup,
} from './_strength'

// crosstalk
import {
  MemberSlides,
  StoreSlides,
} from './_crosstalk'

// interview
import {
  ScheduleSlides,
  OtherInterviewSlides,
} from './_interview'

// environment
import {
  BalanceSlides,
} from './_environment'

// description
import {
  ModalSlides,
} from './_description'

{
  smoothscroll.polyfill();

  const domHamburger = document.querySelectorAll('[data-js="trigger"]');
  if (domHamburger.length > 0) {
    new Hamburger(domHamburger);
  }

  const domHamburgerPartTIme = document.querySelectorAll('[data-js="trigger-part-time"]');
  if (domHamburgerPartTIme) new HamburgerPartTIme(domHamburgerPartTIme);

  const domAnchorScroll = [...document.querySelectorAll('a[href^="#"]:not(.is-noscroll)')];
  if ( domAnchorScroll ) {
    domAnchorScroll.forEach(el => {
      new AnchorScroll(el);
    });
  }

  const domAccordion = document.querySelectorAll('[data-js="accordion"]');
  if (domAccordion) {
    [...domAccordion].forEach(el=>{
      new Accordion(el, false);
    });
  }

  const domMenuAccordion = document.querySelector('[data-js="menu"]');
  if (domMenuAccordion) new MenuAccordion(domMenuAccordion);

  const domAutoMovingSlides = document.querySelectorAll('[data-js="auto-slide"]');
  if (domAutoMovingSlides) {
    [...domAutoMovingSlides].forEach(el=>{
      new AutoMovingSlides(el);
    })
  }

  const domRelatedSlides = document.querySelector('[data-js="related-slide"]');
  if (domRelatedSlides) new RelatedSlides(domRelatedSlides);

  const domScroller = document.querySelector('[data-scroller]');
  if (domScroller) new ScrollManager(domScroller);

  const domLoopTextAnimation = document.querySelector('.js-tick');
  if (domLoopTextAnimation) new LoopTextAnimation(domLoopTextAnimation);
}

// top
{
  const domTopSlides = document.querySelector('[data-js="top-slide"]');
  if (domTopSlides) new TopSlides(domTopSlides);
}

// recruit
{
  const domVisualSlides = document.querySelector('[data-js="visual-slide"]');
  if (domVisualSlides) new VisualSlides(domVisualSlides);

  const domAboutScrollTrigger = document.querySelector('[data-js="about-trigger"]');
  if (domAboutScrollTrigger) new AboutScrollTrigger(domAboutScrollTrigger);

  const domCircleScrollTrigger = document.querySelector('[data-js="about"]');
  if (domCircleScrollTrigger) new CircleScrollTrigger(domCircleScrollTrigger);  
  
  const domPeopleSlides = document.querySelector('[data-js="people-slide"]');
  if (domPeopleSlides) new PeopleSlides(domPeopleSlides);
}

// strength
{
  const domScenePopup = document.querySelectorAll('[data-js="scene-popup"]');
  if (domScenePopup) new ScenePopup(domScenePopup);
}

// crosstalk
{
  const domMemberSlides = document.querySelector('[data-js="member-slide"]');
  if (domMemberSlides) new MemberSlides(domMemberSlides);

  const domStoreSlides = document.querySelector('[data-js="store-slide"]');
  if (domStoreSlides) new StoreSlides(domStoreSlides);
}

// interview
{
  const domScheduleSlides = document.querySelector('[data-js="schedule-slide"]');
  if (domScheduleSlides) new ScheduleSlides(domScheduleSlides);

  const domOtherInterviewSlides = document.querySelector('[data-js="other-interview-slide"]');
  if (domOtherInterviewSlides) new OtherInterviewSlides(domOtherInterviewSlides);
}

// environment
{
  const domBalanceSlides = document.querySelector('[data-js="balance-slide"]');
  if (domBalanceSlides) new BalanceSlides(domBalanceSlides);
}

// description
{
  const domModalSlides = document.querySelectorAll('[data-js="modal-trigger"]');
  if (domModalSlides.length > 0) {
    new ModalSlides(domModalSlides);
  }
}


// const domMagazineSlides = document.querySelector('[data-js="magazine-slide"]');
// if (domMagazineSlides) new MagazineSlides(domMagazineSlides);

// const domAreaSlides = document.querySelectorAll('[data-js="area-slide"]');
// if (domAreaSlides) {
//     [...domAreaSlides].forEach(el=>{
//         new AreaSlides(el);
//     });
// }

// // フィルタリング機能
// const domFilteringCategories = document.querySelector('[data-js="conditions-categories"]');
// if ( domFilteringCategories ) new FilteringCategories(domFilteringCategories);

// const domViewMoreEvent = document.querySelector('[data-js="event"]');
// if (domViewMoreEvent) new ViewMore(domViewMoreEvent);
// const domViewMoreNews = document.querySelector('[data-js="news"]');
// if (domViewMoreNews) new ViewMore(domViewMoreNews);


