import Swiper from 'swiper';
// import { Navigation, EffectFade } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/scss/effect-fade';

class ModalSlides {
  constructor(elms) {
    this.dom = {elms: elms, modal: null, controller: {}};
    this.dom.modal = document.querySelector('[data-js="modal"]');
    this.dom.slider = this.dom.modal.querySelector('.swiper-container');
    this.dom.controller.prev = this.dom.modal.querySelector('[data-js="modal-prev"]');
    this.dom.controller.next = this.dom.modal.querySelector('[data-js="modal-next"]');
    this.dom.controller.closer = this.dom.modal.querySelectorAll('[data-js="modal-closer"]');

    this.slider = null;
    this.option = {
      // modules: [Navigation, EffectFade],
      autoHeight: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      loop: true,
      // spaceBetween: 20,
      navigation: {
        nextEl: this.dom.controller.next,
        prevEl: this.dom.controller.prev,
      },
    };

    this.modalSelectIndex = this.modalSelectIndex.bind(this);

    this.init();
  }

  init() {
    this.slider = new Swiper(this.dom.slider, this.option);

    [...this.dom.elms].forEach(el=>{
      el.addEventListener('click', this.modalSelectIndex);
    });
    [...this.dom.controller.closer].forEach(el=>{
      el.addEventListener('click', this.close);
    });
  }

  open() {
    document.body.dataset.isModal = 'true';
  }

  close() {
    document.body.dataset.isModal = 'false';
  }

  modalSelectIndex(e) {
    const _this = this;
    const index = [].slice.call(this.dom.elms).indexOf(e.currentTarget);
    this.slider.slideToLoop(index, 0); 
    this.open();
  }
}
export default ModalSlides