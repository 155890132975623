import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/splide/css/core';

class AutoMovingSlides {
  constructor(el) {
    this.dom = {el: el};
    this.dom.slider = this.dom.el.querySelector('.splide');

    this.direction = this.dom.el.dataset.dir;
    // this.len = this.dom.slider.querySelectorAll('.splide__slide').length;

    this.slider = null;
    this.option = {
      autoScroll: {
        speed: .5,
      },
      direction: this.direction,
      autoWidth: true,
      pagination: false,
      arrows: false,
      pauseOnHover: true,
      focus: 'center',
      type: 'loop',
      gap: `min(${18 / 16}rem, ${18 / 375 * 100}vw)`,
      rewind: true,
      // loop: true,
      // // perPage: 1,
      // // autoWidth: true,
      speed: 1200,
      // drag: false,
      // arrows: false,
      // arrowPath: false,
      // autoplay: true,
      // pauseOnHover: false,
      // interval: 4000,
      // lazyLoad: 'nearby',
      // drag: false,
      // items:PaginationItem[]
      mediaQuery: 'min',
      breakpoints: {
        768: {
          // type: 'loop',
          gap: `min(${24 / 16}rem, ${24 / 1440 * 100}vw)`,
          // focus: 'center',
        }
      }
    };

    this.init();
  }

  init() {
    this.slider = new Splide(this.dom.slider, this.option);
    this.slider.mount({ AutoScroll });
  }
  
  play() {
    this.slider.Components.Autoplay.play();
  }
}

export default AutoMovingSlides